import React from "react"
import { graphql } from "gatsby"
import { PageLayout, PageTitle, ProjectLink } from "../components"
import { SEO, Utils } from "../utils"
import Container from "react-bootstrap/Container"
import { Image } from "react-bootstrap"



export default ({ data }) => {
  //const allProjects = data.allMarkdownRemark.edges || []
  //const allFeaturedImages = data.allFile.edges || []
  const regex = /\/[projects].*\/|$/
  //const featuredImageMap = Utils.getImageMap(allFeaturedImages, regex, true, 3)

  return (
    <PageLayout>
      <SEO title="Projects" />
      <PageTitle title="Projects" />
      <Container className="text-middle">
        <h3>Fuel Economy and Performance Model</h3>
        <p>2016 Ford Mustang Ecoboost</p>
        <p>Simulation model that determines the fuel economy and performance over the
          EPA CH driving cycles for the 2016 Ford Mustang Ecoboost.
        </p>
        <hr></hr>
        
      <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Mustang_Acceleration_Graph.jpg`}
          
        />
      
        
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Mustang_Engine_Transmission_Speed.jpg`}
          
        />
      
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Mustang_Fuel_Consumed_Graph.jpg`}
          
        />

        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Mustang_Gear_Ratio_Graph.jpg`}
          
        /> 

     
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Mustang_Velocity_Graph.jpg`}
          
        /> 

<hr></hr>


    <h3>Dynamics Model for Simple Track</h3>
        <p>2016 Ford Mustang Ecoboost</p>
        <p>Simulation model that determines how fast a vehicle can be driven on a race track
          without going off-course.
        </p>
        <hr></hr>
        
      <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Yaw_Plot.jpg`}
          
        />
     
        
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Track_Vehicle_Trajectory.jpg`}
          
        />
      
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Long_Velocity_Plot.jpg`}
          
        />

     
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Long_Acceleration_Plot.jpg`}
          
        /> 

     
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Lat_Velocity_Plot.jpg`}
          
        /> 
        
        <Image
          rounded
          width="500"
          height="400"
          src={`../../icons/Lat_Acceleration_Plot.jpg`}
          
        /> 

    <hr></hr>
      </Container>
    </PageLayout>
  )
}

